@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");

  //PRIMARY

  //background color

  .bg-primary {
    background-color: mat.m2-get-color-from-palette($primary-palette);
  }

  .bg-primary-50 {
    background-color: mat.m2-get-color-from-palette($primary-palette, 50);
  }

  .bg-primary-A400 {
    background-color: mat.m2-get-color-from-palette($primary-palette, A400);
  }

  .bg-primary-contrast {
    background-color: mat.m2-get-color-from-palette(
      $primary-palette,
      default-contrast
    );
  }

  //text color

  .text-primary {
    color: mat.m2-get-color-from-palette($primary-palette);
  }

  .text-primary-contrast {
    color: mat.m2-get-color-from-palette($primary-palette, default-contrast);
  }

  .text-primary-100 {
    color: mat.m2-get-color-from-palette($primary-palette, 100);
  }
  .text-primary-A200 {
    color: mat.m2-get-color-from-palette($primary-palette, A200);
  }

  .text-primary-A400 {
    color: mat.m2-get-color-from-palette($primary-palette, A400);
  }

  //border color

  .border-primary {
    border-color: mat.m2-get-color-from-palette($primary-palette) !important;
  }

  .border-primary-A200 {
    border-color: mat.m2-get-color-from-palette($primary-palette, A200) !important;
  }

  .border-primary-A400 {
    border-color: mat.m2-get-color-from-palette($primary-palette, A400) !important;
  }

  //-----------------------------------------------

  //ACCENT

  //bg color
  .bg-accent {
    background-color: mat.m2-get-color-from-palette($accent-palette);
  }

  .bg-accent-contrast {
    background-color: mat.m2-get-color-from-palette(
      $accent-palette,
      default-contrast
    );
  }

  //text color
  .text-accent {
    color: mat.m2-get-color-from-palette($accent-palette);
  }

  //border color

  .border-accent {
    border-color: mat.m2-get-color-from-palette($accent-palette);
  }

  .border-left-accent {
    border-left-color: mat.m2-get-color-from-palette($accent-palette);
  }
}
