// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

@use "./app/app.component.material.scss" as appClasses;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$main-palette: (
  50: #eaeef1,
  100: #cad6dc,
  200: #a7bac5,
  300: #849eae,
  400: #698a9c,
  500: #4f758b,
  600: #486d83,
  700: #3f6278,
  800: #36586e,
  900: #26455b,
  A100: #a1d6ff,
  A200: #6ec0ff,
  A400: #3baaff,
  A700: #219fff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$accent-palette: (
  50: #fffaec,
  100: #fff2d0,
  200: #ffeab0,
  300: #ffe290,
  400: #ffdb79,
  500: #ffd561,
  600: #ffd059,
  700: #ffca4f,
  800: #ffc445,
  900: #ffba33,
  A100: #ffffff,
  A200: #ffffff,
  A400: #fff3de,
  A700: #ffeac4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$web-primary: mat.define-palette(mat.$indigo-palette);
//$web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$web-primary: mat.m2-define-palette($main-palette);
$web-accent: mat.m2-define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$web-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
      warn: $web-warn,
    ),
  )
);

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

router-outlet + * {
  animation: fade 0.5s;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);

//@include homeComponent.theme($web-theme);
/* You can add global styles to this file, and also import other style files */

@include appClasses.color($web-theme);

//fonts

@font-face {
  font-family: "Archivo";
  src: url(assets/fonts/Archivo/Archivo.ttf) format("truetype");
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

* {
  font-family: "Archivo";
  font-size: 1rem;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
}

.success-alert {
  background-color: #d4edda;
  color: #155724;
  white-space: pre-wrap;
  border-radius: 5px;
}
.error-alert {
  background-color: #f8d7da;
  color: #721c24;
  white-space: pre-wrap;
  border-radius: 5px;
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  background-color: unset !important;
  color: unset !important;
}

html,
body {
  height: 100%;
  font-size: 15px;
}
body {
  margin: 0;
}

body:after {
  display: none;
  content: url("/assets/img/login-background.png"),
    url("/assets/img/login-background-mobile.png");
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
